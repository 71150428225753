<template>
  <div>
    <div>
      <div
        class="row justify-content-between align-items-center w-100 my-4 mx-6"
      >
        <h4 class="">Fiili Kullanım Amacı</h4>
        <button class="btn btn-primary mx-6" @click="addFileModal = true">
          <p style="font-size: 13px" class="text-white">
            <i class="flaticon2-plus mr-1"></i> Yeni Amaç Ekle
          </p>
        </button>
      </div>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <hr class="w-100" />
      </div>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Fiili Kullanım Nitelikleri Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.email="{ item }">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template v-slot:item.isActive="{ item }">
          <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex justify-content-end w-100">
            <button @click="updateUsage(item)">
              <i class="flaticon-eye icon-lg"></i>
            </button>
            <button class="border-0" @click="openDeleteHandler(item)">
              <i class="flaticon2-trash text-dark text-md ml-4"></i>
            </button>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4>Fiili Kullanım Amacı Ekle</h4>
      </template>
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <div class="from-group">
              <MultiSelect
                v-model="actualuse_purpose"
                name="actualuse_purpose"
                :options="purposes"
                :multiple="false"
                :defaultValue="actualuse_purpose ? actualuse_purpose : ''"
                label="Filli Kullanım Niteliği"
                placeholder="Fiili Kullanım Niteliği Seçiniz"
                id="purpose"
              />
            </div>
          </div>
          <div class="col-12">
            <label for="">Adı</label>
            <input type="text" class="form-control" v-model="amac" />
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="removeUpdate">
            Vazgeç
          </b-button>
          <b-button variant="success" class="ml-4" @click="addUsage"> Kaydet </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation
      :url="deleteOperationUrl"
      :params="deleteIds"
      @updateList="getList"
      modalid="deleteUsage"
    />

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { USAGE,PURPOSE } from "@/core/services/store/usage.module.js";
import { mapGetters } from "vuex";

export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,

      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "name" },
        { text: "Nitelik", value: "purpose_name" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
      nitelik: "",
      purposes: [],
      actualuse_purpose: "",
      amac:"",
      updateModal:0,
      usage_id: ""

    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Fiili Kullanım Amaçları", route: "/sistem-kullanicilari" },
    ]);
  },
  created() {
    if(this.getPurpose.length > 0){
            this.purposes = this.setPurposeSelectValue(this.getPurpose);
          }else{
            this.$store.dispatch(PURPOSE,this.myUser).then((response)=>{
                this.purposes = this.setPurposeSelectValue(response)
            })
          }
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `user/list?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}`;
      if (this.search) {
        query += `&search=${this.search}`;
      }
      let payload = { user_token: this.myUser, query: query };
      this.$store.dispatch(USAGE, payload).then((response) => {
        this.totalItems = response.length;
        this.items = response;
        this.pagination = {
          current_page: 1,
          total_pages: Math.ceil(response.length / 10),
          total_items: response.length,
        };
        this.loading = false;
      });

    
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    setPurposeSelectValue(purposes) {
      const setPurposesSelect = purposes.map((index, key) => {
        return { value: index._id.$oid, text: index.name };
      });
      return setPurposesSelect;
    },
    async addUsage(){
      const data = {user_token : this.myUser,purpose_id:this.actualuse_purpose.value,name:this.amac}
      if(!this.updateModal){
        await this.$ApiService
        .post("admin/usageattribute", data)
        .then((res) => {
          if (res.data.status.code === 200) {
            this.getList()
            this.amac=""
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            )
            this.addFileModal = false
            
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Hata");
        });
      }else{
        data["usageattribute_id"] = this.usage_id
        const formData = new FormData()
        for (const key in data) {
          formData.append(key, data[key]);
      }
        await this.$ApiService
        .put("admin/usageattribute", formData)
        .then((res) => {
          if (res.data.status.code === 200) {
            this.getList()
            this.amac=""
            this.updateModal = false
            this.usage_id = ""
            this.actualuse_purpose = ""
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            )
            this.addFileModal = false
            
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        })
        .catch((err) => {
          this.$generateNotification(this, "error", "Hata");
        });
      }
      
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `admin/usageattribute`;
      this.deleteIds = `?user_token=${this.myUser}&usageattribute_id=${item._id.$oid}`;
      this.$bvModal.show('modal-deleteOperationdeleteUsage');
    },
    updateUsage(item){
      this.addFileModal = true
      const selectedValue = this.getPurpose.find(purpose=>purpose._id.$oid === item.purpose_id)
      this.updateModal = true
      this.usage_id = item._id.$oid
      this.amac = item.name
      this.actualuse_purpose = {text:selectedValue.name,value:selectedValue._id.$oid}
    
    },
    removeUpdate(){
      this.updateModal = false
      this.usage_id = ""
      this.actualuse_purpose = ""
      this.amac = ""
      this.addFileModal = false
    }
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
      
    },
    
     
  },
  computed: {
    ...mapGetters(["myUser", "getPurpose"]),
  },
};
</script>
